import React from "react"

import Page from '../components/Page'
import Layout from "../components/Layout"
import StoryblokService from '../utils/storyblok-service'
import ImagesService from '../utils/images-service'
import i18n from "../i18n/i18n"

import SEO from "../components/SEO"

class RegularPage extends React.Component {

  constructor(props) {
    super()
    this.props = props

    this.state = {
      story: {
        content: this.props.pageContext.story ? JSON.parse(this.props.pageContext.story.content) : {},
      },
      language: this.props.pageContext.language,
      slug: this.props.pageContext.slug,
      headline: this.props.pageContext.headline
    }
    i18n.changeLanguage(this.props.pageContext.language)
  }

  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search)
    let { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.pageContext.story.full_slug}`, { resolve_relations: 'contact.profile,newslist.news' })
    return story
  }

  async componentDidMount() {
    let story = await this.getInitialStory()

    // let locales = LocalesService(story.alternates)
    // this.setState({ locales })

    if(story.content) this.setState({ story })
    if(story.name) this.setState({ headline: story.name })
    setTimeout(() => StoryblokService.initEditor(this), 200)
  }

  render() {
    return (
       <Layout location={this.props.location}>
         <SEO 
          title={(this.state.story.content.seo.title === '' ? this.state.headline : this.state.story.content.seo.title)}
          lang={this.state.language}
          prefix="og: https://ogp.me/ns# website: https://ogp.me/ns/website# fb: https://ogp.me/ns/fb#"
          description={this.state.story.content.seo.description}
          meta={[
          {
            property: 'og:locale',
            content: this.props.pageContext.language
          }, {
            property: 'og:type',
            content: 'website'
          }, {
            property: 'og:locale',
            content: this.props.pageContext.language
          }, {
            property: 'og:title',
            content: (this.state.story.content.seo.og_title === '' ? this.state.story.name : this.state.story.content.seo.og_title)
          }, {
            property: 'og:description',
            content: (this.state.story.content.seo.og_description === '' ? this.state.story.description : this.state.story.content.seo.og_description)
          }, {
            property: 'og:site_name',
            content: 'Gerald Asamoah Stiftung'
          }, {
            property: 'og:image',
            content: (this.state.story.content.seo.og_image === '' ? null : ImagesService(this.state.story.content.seo.og_image, true, { path: '1920x1080/smart'}))
          }, {
            property: 'og:image:width',
            content: '1920'
          }, {
            property: 'og:image:height',
            content: '1080'
          }, {
            property: 'og:url',
            content: `https://www.gerald-asamoah-stiftung.de/${ this.props.pageContext.slug }`
          }, {
            property: 'fb:app_id',
            content: '815996722311997'
          }, {
            property: 'twitter:title',
            content: (this.state.story.content.seo.twitter_title === '' ? this.state.story.name : this.state.story.content.seo.twitter_title)
          }, {
            property: 'twitter:description',
            content: (this.state.story.content.seo.twitter_description === '' ? this.state.story.description : this.state.story.content.seo.twitter_description)
          }]}
          // locales={this.state.locales}
          structuredData={JSON.parse(this.state.story.content.structured_data)}
          />
         <Page blok={this.state.story.content} />
      </Layout>
    )
  }
}

export default RegularPage